<template>
  <div>
    <ReportsCard 
      :headers="assignedHeaders"
      hasLoadedGetter="report/HasAssignedReportsPageLoaded"
      itemCountGetter="report/GetAssignedReportCount"
      itemGetter="report/GetAssignedReports"
      itemLoadGetter="report/loadAdminAssignedReports"
      refreshEvent="report/reloadAssignedReportsPages"
      cardTitle="Reports assigned to me"
      :showId="true"
      :allowExpand="true"
      :allowManagement="true"
    />
    <ReportsCard 
      :headers="unassignedHeaders"
      hasLoadedGetter="report/HasUnassignedReportsPageLoaded"
      itemCountGetter="report/GetUnassignedReportCount"
      itemGetter="report/GetUnassignedReports"
      itemLoadGetter="report/loadAdminUnassignedReports"
      refreshEvent="report/reloadUnassignedReportsPages"
      cardTitle="Unassigned reports"
      :showId="true"
      :allowExpand="false"
      :allowManagement="false"
    />
  </div>
</template>

<script>
import ReportsCard from '@/views/components/report/ReportsCard.vue'

export default {
  components: {
    ReportsCard,
  },
  data() {
    return {
      assignedHeaders: [
        { text: 'Reported player', value: 'reportee' },
        { text: 'Report author', value: 'reporter' },
        { text: 'Report date', value: 'timestamp' },
        { text: 'Offense', value: 'offense' },
      ],
      unassignedHeaders: [
        { text: 'Reported player', value: 'reportee' },
        { text: 'Report author', value: 'reporter' },
        { text: 'Report date', value: 'timestamp' },
        { text: 'Offense', value: 'offense' },
        { text: '', value: 'assign' },
      ],
    }
  },
}
</script>
